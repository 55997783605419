import React, { useEffect, useState } from 'react';
import { Modal } from '@components/modal';
import { Button } from '@components/button';
import auraLogo from '@components/brand/AuraAppLogo.svg';
import { useAuthContext } from '@state/auth';
import Cookies from 'js-cookie';
import { hashData } from '@util/hash';

export default function ChooseAuraVersionModal({ isVisible, onClose }) {
  const { user } = useAuthContext();
  const [userCookieKey, setUserCookieKey] = useState('');

  useEffect(() => {
    const setUserKey = async () => {
      if (user) {
        const userId = user?.id;
        if (userId) {
          const hashedId = await hashData(String(userId));
          setUserCookieKey(`selectedPlatformVersion_${hashedId}`);
        }
      } else {
        onClose();
      }
    };
    setUserKey();
  }, [user, onClose]);

  const goAura3 = () => {
    Cookies.set(userCookieKey, 'aura3', { expires: 365 });
    window.location.href = '/explore';
  };

  const stayLegacy = () => {
    Cookies.set(userCookieKey, 'legacy', { expires: 7 });
    onClose();
  };

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      isClosable={false}
      hasCloseButton={false}
      footerButtonPropsList={[]}
      contentStyle={{ minHeight: 'auto', maxHeight: 'none' }}
    >
      <div
        style={{
          textAlign: 'center',
          maxWidth: '560px',
          margin: '0 auto',
        }}
      >
        <img
          src={auraLogo}
          alt="Aura Logo"
          style={{
            width: 140,
            margin: '0 auto',
            marginBottom: '1.5rem',
            display: 'block'
          }}
        />
        <h2
          style={{
            fontSize: '1.25rem',
            fontWeight: '600',
            marginBottom: '0.75rem'
          }}
        >
          The All-New Aura Platform—Now Faster and More Accurate for Workforce Intelligence Insights
        </h2>
        <p
          style={{
            marginBottom: '1.5rem',
            fontSize: '0.95rem',
            lineHeight: 1.4,
            textAlign: 'left'
          }}
        >
          We’ve upgraded our platform to help you uncover deeper insights and discover companies like never before.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
          <Button
            type="secondary"
            title="Stay on legacy version"
            onClick={stayLegacy}
            style={{ color: '#263238' }}
          />
          <Button
            type="primary"
            icon="rocket"
            iconSize="small"
            iconFill="transparent"
            title="Switch to Aura 3.0"
            viewBox="0 0 20 20"
            iconPlacement="right"
            iconStyle={{ marginLeft: '0.5rem', marginRight: 0 }}
            onClick={goAura3}
            style={{
              background: '#AD1655',
              borderColor: '#CB467D',
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
