export async function hashData(data) {
    const encoder = new TextEncoder();
    const msgUint8 = encoder.encode(data);
  
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
  
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  
    return hashHex;
  }
  